.shareBoxBg {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    /* background-color: red; */

}

.shareBox {
    width: 380px;
    height: 770px;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 30px;
    /* border: 2px solid rgb(87 87 87 / 10%); */
    padding: 1rem;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

/* width */
.shareBox::-webkit-scrollbar {
    width: 0px;
}

/* Track */
.shareBox::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.shareBox::-webkit-scrollbar-thumb {
    background: #888;
}

.shareBoxProfile {
    display: flex;
    align-items: center;
}

.shareBoxProfileImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.shareBoxProfileImg img {
    max-width: 100%;
}

.shareBoxProfileContent {
    padding-left: 10px;
}

.shareBoxProfileContent h4 {
    font-size: 16px;
    text-transform: capitalize;
    margin: 0;
}

.shareBoxProfileContent p {
    margin: 0;
    font-size: 12px;
}

.shareBoxProfileBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shareBoxProfileFollow a {
    padding: 4px 15px;
    background-color: rgb(203 203 203 / 20%);
    color: var(--secondary);
    font-size: 12px;
    border-radius: 6px;
    text-transform: capitalize;
    font-family: mediumfont;
}

.shareBoxProfileFollow a:hover {
    color: var(--secondary);
}

.sharePageProductBox {
    width: 100%;
}

.sharePageProductBox {
    width: 100%;
    height: 560px;
    box-shadow: 0px 8px 39px rgb(0 0 0 / 9%);
    display: flex;
    margin-top: 5rem;
}

.sharePageProductSlider {
    width: 100%;
    height: 345px;
    margin-top: 1rem;
}



.sharePageProductSlider .swiper {
    width: 100%;
    height: 100%;
}

.sharePageProductSlider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.sharePageProductSlider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sharePageProductSlider .swiper-slide {
    width: 100%;
}

.sharePageProductImgBox {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.service .sharePageProductImgBox {
    background-size: contain;
    background-color: var(--dark);
}

.shareBoxContent .productImgSliderTitle {
    color: var(--dark);
    font-size: 20px;
}

.shareBoxContent .productImgSliderPrice ul li span {
    color: var(--dark);
    font-size: 26px;
    font-family: semibold;
}

.shareBoxContent .productImgSliderDetail .accordion-button {
    border-color: rgb(87 87 87 / 30%) !important;
    color: var(--dark);
    font-family: mediumfont;
}

.shareBoxContent .productImgSliderDetail .accordion-button::after {
    background-image: url(../../img/icons/down-arrow.png);
}

.shareBoxContent .productImgSliderDetail .accordion-button:not(.collapsed)::after {
    background-image: url(../../img/icons/up.png);
}

.shareBoxContent .productImgSliderDetail .accordion-body {
    color: var(--dark);
}

.shareBoxContent .productImgSliderPrice ul li small {
    color: var(--dark);
    font-size: 16px;
}

.shareProductAction {
    margin: 1rem 0 0 0;
}

.shareProductAction ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.shareProductAction ul li {
    font-size: 12px;
    padding: 5px 10px;
    background-color: #EFF8FF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark);
}

.shareProductAction ul li img {
    max-width: 20px;
    margin-right: 5px;
}

.sharePageProductSlider .swiper-scrollbar {
    background: rgba(3, 3, 3, 0.26);
}

.sharePageProductSlider .swiper-scrollbar-drag {
    background: var(--dark);
}

.sharePageProductSlider.service .swiper-scrollbar {
    background: rgb(242 250 255 / 26%);
}

.sharePageProductSlider.service .swiper-scrollbar-drag {
    background: var(--white);
}