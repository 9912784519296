* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: regular;
}

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  color: var(--dark);
}

p {
  color: var(--text);
}

/* Color Variables */
:root {
  --bg-dark: #000813;
  --bg-light: #F2FAFF;
  --white: #fff;
  --dark: #000813;
  --text: #575757;
  --primary: #092750;
  --secondary: #0C8CE9;
  --light: #C5E6FF;
}

@font-face {
  font-family: lightfont;
  src: url('./font/Poppins-Light.otf');
}

@font-face {
  font-family: regular;
  src: url('./font/Poppins-Regular.otf');
}

@font-face {
  font-family: mediumfont;
  src: url('./font/Poppins-Medium.otf');
}

@font-face {
  font-family: semibold;
  src: url('./font/Poppins-SemiBold.otf');
}

@font-face {
  font-family: boldfont;
  src: url('./font/Poppins-Bold.otf');
}

@font-face {
  font-family: extrabold;
  src: url('./font/Poppins-ExtraBold.otf');
}

@font-face {
  font-family: italicfont;
  src: url('./font/brushtion.ttf');
}

@media (min-width: 1550px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1600px !important;
  }
}

.pageTitleMargen {
  padding: 8rem 0 4rem 0;
}

.pageTitle h1 {
  text-align: center;
  font-size: 60px;
  font-family: mediumfont;
  background: linear-gradient(268.8deg, var(--dark) 0.37%, var(--secondary) 99.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}




@media (min-width: 319.98px) and (max-width: 379.98px) {
  p {
    font-size: 14px !important;
  }

  #order1 {
    order: 1;
  }

  #order2 {
    order: 2;
  }

  .pageTitle h1 {
    font-size: 26px;
  }

  .pageTitle h1 br {
    display: none;
  }
}

@media (min-width: 380px) and (max-width: 424.98px) {
  p {
    font-size: 14px !important;
  }

  #order1 {
    order: 1;
  }

  #order2 {
    order: 2;
  }

  .pageTitle h1 {
    font-size: 26px;
  }

  .pageTitle h1 br {
    display: none;
  }
}

@media (min-width: 425px) and (max-width: 575.98px) {
  p {
    font-size: 14px !important;
  }

  #order1 {
    order: 1;
  }

  #order2 {
    order: 2;
  }

  .pageTitle h1 {
    font-size: 26px;
  }

  .pageTitle h1 br {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768.98px) {
  .pageTitle h1 {
    font-size: 26px;
  }

  .pageTitle h1 br {
    display: none;
  }

  #order1 {
    order: 1;
  }

  #order2 {
    order: 2;
  }
}

@media (min-width: 769px) and (max-width: 991.98px) {
  .pageTitle h1 {
    font-size: 26px;
  }

  .pageTitle h1 br {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
  .pageTitle h1 {
    font-size: 26px;
  }

  .pageTitle h1 br {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1199.98px) {
  .pageTitle h1 {
    font-size: 26px;
  }

  .pageTitle h1 br {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1279.98px) {}

@media (min-width: 1280px) and (max-width: 1299.98px) {}

@media (min-width: 1300px) and (max-width: 1359.98px) {}

@media (min-width: 1360px) and (max-width: 1439.98px) {}

@media (min-width: 1440px) and (max-width: 1536.98px) {}

@media (min-width: 1537px) and (max-width: 1920px) {}