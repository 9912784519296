.homeHeaderStorBtn {
    width: 200px;
    height: 70px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    background-color: var(--white);
    margin-right: 1.5rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    padding: 10px;
}

.storeImg {
    margin-right: 10px;
}

.storeName span {
    font-size: 10px;
    text-transform: uppercase;
    color: var(--text);
}

.storeName p {
    margin: 0;
    font-family: mediumfont;
    font-size: 20px;
    text-transform: capitalize;
}

@media (min-width: 319.98px) and (max-width: 379.98px) {
    .homeHeaderStorBtn {
        margin-right: 0;
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .homeHeaderStorBtn {
        margin-right: 0;
        width: 90%;
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .homeHeaderStorBtn {
        margin-right: 0;
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {}

@media (min-width: 769px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) and (max-width: 1279.98px) {}

@media (min-width: 1280px) and (max-width: 1299.98px) {}

@media (min-width: 1300px) and (max-width: 1359.98px) {}

@media (min-width: 1360px) and (max-width: 1439.98px) {}

@media (min-width: 1440px) and (max-width: 1536.98px) {}

@media (min-width: 1537px) and (max-width: 1920px) {}