.navigationBg {
    padding: 10px 0;
    position: absolute;
    top: 0;
    width: 100%;
    /* background-color: var(--white);
    z-index: 999999; */
}

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(to top right, var(--secondary), var(--primary));
    opacity: 0;
    clip-path: circle(25px at calc(100% - 210px) 34px);
    transition: all 0.3s ease-in-out;
}

#active:checked~.wrapper {
    clip-path: circle(75%);
    background-image: linear-gradient(to top right, var(--secondary), var(--primary));
    opacity: 1;
}

.menu-btn {
    position: relative;
    z-index: 2;
    right: 0px;
    top: 0px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.wrapper ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    display: inherit !important;
}

.wrapper ul li {
    margin: 2.5rem 0;
}

.wrapper ul li a {
    color: none;
    text-decoration: none;
    font-size: 50px;
    font-family: boldfont;
    padding: 5px 30px;
    color: #fff;
    border-radius: 6px;
    position: relative;
    line-height: 50px;
    transition: all 0.3s ease;
}

.wrapper ul li a:hover {
    background-color: var(--white);
    color: var(--primary);
}

.wrapper ul li a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5em;
    color: rgba(0, 0, 0, .1);
    border-radius: 50%;
    z-index: -1;
    opacity: 0;
    font-family: extrabold;
    text-transform: uppercase;
    letter-spacing: 500px;
    transition: letter-spacing 0.5s, left 0.5s;
}

.wrapper ul li a:hover:before {
    content: attr(data-text);
    opacity: 1;
    left: 50%;
    letter-spacing: 10px;
}

.navigationItem ul li input[type="checkbox"] {
    display: none;
}



@keyframes rotate {
    0% {
        filter: hue-rotate(0deg);
    }

    100% {
        filter: hue-rotate(360deg);
    }
}


.navigationItem ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
}



.menu_click {
    margin-top: 8px;
}


.menu_click div {
    width: 35px;
    height: 4px;
    margin-bottom: 6px;
    border-radius: 5px;
    background-color: var(--dark);
    transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91), opacity 500ms, background-color 250ms;
}

.menu_click.active div {
    background-color: var(--white);

}

.menu_click.active #top {
    transform: translateY(10px) rotate(-135deg);
}

.menu_click.active #middle {
    opacity: 0;
    transform: rotate(135deg);
}

.menu_click.active #bottom {
    transform: translateY(-10px) rotate(-45deg);
}


@media (min-width: 319.98px) and (max-width: 379.98px) {
    .navigationItem ul li a img {
        max-width: 110px;
    }

    .navigationItem ul {
        width: 100%;
    }

    .wrapper ul li a {
        font-size: 30px;
    }

    .wrapper ul li {
        margin: 1rem 0;
    }

    .wrapper {
        clip-path: circle(25px at calc(100% - 46px) 34px);
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .navigationItem ul li a img {
        max-width: 110px;
    }

    .navigationItem ul {
        width: 100%;
    }

    .wrapper ul li a {
        font-size: 30px;
    }

    .wrapper ul li {
        margin: 1rem 0;
    }

    .wrapper {
        clip-path: circle(25px at calc(100% - 46px) 34px);
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .navigationItem ul li a img {
        max-width: 110px;
    }

    .navigationItem ul {
        width: 100%;
    }

    .wrapper ul li a {
        font-size: 30px;
    }

    .wrapper ul li {
        margin: 1rem 0;
    }

    .wrapper {
        clip-path: circle(25px at calc(100% - 46px) 34px);
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .navigationItem ul li a img {
        max-width: 110px;
    }

    .navigationItem ul {
        width: 100%;
    }

    .wrapper ul li a {
        font-size: 30px;
    }

    .wrapper ul li {
        margin: 1rem 0;
    }

    .wrapper {
        clip-path: circle(25px at calc(100% - 46px) 34px);
    }
}

@media (min-width: 769px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) and (max-width: 1279.98px) {}

@media (min-width: 1280px) and (max-width: 1299.98px) {}

@media (min-width: 1300px) and (max-width: 1359.98px) {}

@media (min-width: 1360px) and (max-width: 1439.98px) {}

@media (min-width: 1440px) and (max-width: 1536.98px) {}

@media (min-width: 1537px) and (max-width: 1920px) {}